import ApiInstance from "../configs/ApiInstance";

async function GetAllArticlesByAuthor(author,currentPage) {
  try {
    const response = await ApiInstance.articleService.get(`/list/${author}`,
      {
        params: { size: 8, page: currentPage - 1, sort: 'createdDate,desc' }, // Assuming `createdDate` is the date field
      }
    );
    return {
      content: response.data.content,
      totalPages: response.data.totalPages,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default GetAllArticlesByAuthor;
