import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import LeftAdd from './LeftAdd';
import RightAdd from './RightAdd';

function Content({ article }) {
    return (
        <Container maxWidth='xl' sx={{ mt: 3 }}>
            <Grid container spacing={2}>
                <Grid 
                    item 
                    md={2} 
                    mt={56}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        height: '100%'  
                    }}
                >
                    
                </Grid>
                <Grid 
                    item 
                     md={8}
                >
                    <Box
                        sx={{
                            minHeight: "1000px",
                            mb: 10,
                         }}
                    >
                        <Stack spacing={3}>
                            <Stack spacing={1}>
                                <Typography
                                    variant='h2'
                                    fontFamily={'serif'}
                                    sx={{ fontWeight: 700 }}
                                >
                                    {article.title}
                                </Typography>
                                <Typography
                                    variant='body1'
                                    fontFamily={'serif'}
                                    color="text.secondary"
                                >
                                    By {article.author}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    fontFamily={'serif'}
                                    color="text.secondary"
                                >
                                    {article.updatedAt}
                                </Typography>
                            </Stack>
                            <Divider />
                            <Typography
                                variant="h6"
                                component="div"
                                fontFamily={'serif'}
                                sx={{
                                    "& p": {
                                        margin: "0",
                                        paddingBottom: "0",
                                    },
                                    "& br": {
                                        margin: "2px 0",
                                        display: "block",
                                        content: '""',
                                    },
                                }}
                                dangerouslySetInnerHTML={{ __html: article.content }}
                            />
                        </Stack>
                    </Box>
                    <Divider sx={{mb:5}} />
                </Grid>
                <Grid 
                    item 
                    md={2} 
                    mt={55}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        height: '100%', 
                    }}
                >
                   
                </Grid>
            </Grid>
        </Container>
    );
}

export default Content;
