import axios from 'axios'
import configs from './endpoints.json'

const articleService = axios.create({
    baseURL: `${configs.articleService}`
})

const userAwsService = axios.create({
    baseURL: `${configs.userAwsService}`
})

const ApiInstance = {
    articleService,
    userAwsService
}

export default ApiInstance;