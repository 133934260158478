import React, { useEffect, useState } from 'react';
import { Grid, Container, Box, Typography, Card, CardMedia, CardContent, CardActions, Chip } from '@mui/material';
import { navigateToUrl } from 'single-spa';
import GetArticleImage from '../services/GetArticleImage';


function ArticleGrid({ articles }) {
  const [articleImages, setArticleImages] = useState({});

  useEffect(() => {
    const fetchImages = async () => {
      const images = await Promise.all(
        articles.map(async (article) => {
          const imageUrl = await GetArticleImage(article.articleImageKey);
          return { [article.id]: imageUrl };
        })
      );
      setArticleImages(images.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
    };

    fetchImages();
  }, [articles]);

  if (!articles || articles.length === 0) {
    return (
      <Container maxWidth="xl">
        <Box
           sx={{
            mt: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            minHeight: '85vh',
            minWidth: {
              xl: '120vh'
            },
            border: "2px solid black",
            borderRadius: "15px"
          }}
        >
          <Typography variant="h3" component="h2">
            No Articles Available
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={5} mt={1} mb={5}>
        {articles.map((article) => (
          <Grid item key={article.id} xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "350px",
                minHeight: "100%",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.02)",
                },
                marginBottom: 1,
                background: 'black',
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={articleImages[article.id] || ""}
                alt={article.title}
                sx={{
                  objectFit: "cover",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
                onClick={() => navigateToUrl(`/myarticles/${article.id}`)}
              />
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  justifyContent: 'flex-start',
                }}
              >
                <Typography fontSize={"1.05rem"} sx={{ color: "white" }}  >
                  {article.title}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "auto",
                    padding: 1,
                  }}
                >
                  <Chip
                    sx={{
                      bgcolor:
                        article.status === "ACCEPTED"
                          ? "#00E600"
                          : article.status === "REJECTED"
                            ? "#E62600"
                            : "black",
                      color: 'white'
                    }}
                    label={article.status} />
                </Box>
              </CardContent>

            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ArticleGrid;
