import React, { useEffect, useState } from 'react';
import ArticleGrid from './components/ArticleGrid';
import GetAllArticlesByAuthor from './services/GetAllArticlesByAuthor';
import { Typography, Divider } from '@mui/material';
import Pagination from './components/Pagination';

function Screen() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [articles, setArticles] = useState([]);
  const [nickname, setNickname] = useState('');

  useEffect(() => {
    const fetchArticles = async (page) => {
      const storedUser = sessionStorage.getItem('user');
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        const nickname = getUserAttribute(parsedUser, 'nickname');
        setNickname(nickname);
        try {
          const articlesDetails = await GetAllArticlesByAuthor(nickname, currentPage);
          const sortedArticles = articlesDetails.content.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)); // Sort articles by date in descending order
          setArticles(sortedArticles);
          setTotalPages(articlesDetails.totalPages);
        } catch (error) {
          console.error("Error fetching articles:", error);
        }
      }
    };
    fetchArticles(currentPage); 
  }, [currentPage]);
  

    const getUserAttribute = (user, name) => {
        const attribute = user.UserAttributes.find(attr => attr.Name === name);
        return attribute ? attribute.Value : 'N/A';
    };

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

  return (
    <>
       <Divider textAlign="right" sx={{ mt: 5 }}>
                <Typography variant="h2" sx={{
                    textAlign: 'left',
                    fontSize: { xs: 'h5.fontSize', sm: 'h3.fontSize', md: 'h2.fontSize' }
                }}>
                    My Articles
                </Typography>
            </Divider>
      <ArticleGrid articles={articles} />
      {articles.length >= 9 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}

export default Screen;
