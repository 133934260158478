import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Article from './components/Article';
import Screen from './Screen'


function App() {
  return (
    <Router>
        <Routes>
            <Route path='/myarticles/' element={<Screen/>} />
            <Route path='/myarticles/:id' element={<Article/>} />
        </Routes>
    </Router>
  )
}

export default App